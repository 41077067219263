import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { updateCanvas } from "../utils/updateCanvas"
import Button from "./button"

const StyledDesignCard = styled.div`
  img.card-image {
    width: 100%;
    margin: 0;
  }
  position: relative;
  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: 200ms ease-in-out;
  }
  &:hover {
    .hover-overlay {
      opacity: 1;
    }
  }
`

const DesignCard = props => {
  const [imageSrc, setImageSrc] = useState("")
  useEffect(() => {
    loadImage()
  }, [])
  const loadImage = async () => {
    const bgImg = "/images/tshirt-Front-White.jpg"
    const compImg = await updateCanvas(bgImg, props.path)
    setImageSrc(compImg)
  }
  return (
    <StyledDesignCard>
      <div className="hover-overlay">
        <Button>Buy now</Button>
      </div>
      <img src={imageSrc} alt="" className="card-image" />
    </StyledDesignCard>
  )
}

export default DesignCard
