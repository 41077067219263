import Axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import DesignCard from "../components/designCard"
import Layout from "../components/layout"
import LoaderContainer from "../components/loaderContainer"
import Narrow from "../components/narrow"
import { db } from "../utils/siteVars"

const StyledBrowsePage = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
`

const BrowsePage = () => {
  const [loading, setLoading] = useState(true)
  const [designs, setDesigns] = useState([])
  const query = useStaticQuery(graphql`
    query browseQuery {
      bgImg: file(relativePath: { eq: "bg_make.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const c = "rgba(30,50,100,0.8)"
  const layeredBG = [
    `linear-gradient(${c}, ${c})`,
    query.bgImg.childImageSharp.fluid,
  ]

  useEffect(() => {
    Axios.get(db + "designs")
      .then(res => {
        setDesigns(res.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err.response)
        setLoading(false)
      })
  }, [])

  return (
    <Layout title="Browse designs" bg={layeredBG}>
      <LoaderContainer active={loading} />
      <StyledBrowsePage>
        <Narrow>
          <h1>Browse designs</h1>
          <div className="grid-container">
            {designs.map(design => (
              <DesignCard
                path={`/designImages/${design.name}`}
                key={design._id}
              />
            ))}
          </div>
        </Narrow>
      </StyledBrowsePage>
    </Layout>
  )
}

export default BrowsePage
